import * as React from "react"

import CoderReference from "@components/CoderReference"

import style from "./CoderReferencesPage.scss"

const CoderReferencesPage: React.FunctionComponent = () => {
  return (
    <div className={style.coderReferencesPage}>
      <div className={style.contentContainerLeft}>
        <div className={style.contentContainerLeftElement}/>
        <h1 className={style.contentContainerLeftTitle}>
          coding<br/>
          <span className={style.contentContainerLeftTitleTitleSub}>references</span>
        </h1>
        <div className={style.contentContainerLeftNumber}>
          01
        </div>
      </div>
      <div className={style.contentContainerRight}>
        <CoderReference/>
      </div>
    </div>
  )
}

export default CoderReferencesPage
