import * as React from "react"
import SVG from "react-inlinesvg"

import IconsContainer, { EIcons } from "@components/IconsContainer"
import PagerBtn, { EDirection } from "@components/PagerBtn"
import iconMultiMedia from "@static/svg/multimedia.svg"

import style from "./CoderReference.scss"

const CoderReference: React.FunctionComponent = () => {
  return (
    <div className={style.coderReference}>
      <div className={style.coderReferenceData}>
        <h4 className={style.coderReferenceDataFlexRow}>client:
          <span className={style.coderReferenceDataClientData}> ScreamingBox</span>
        </h4>
        <h4 className={style.coderReferenceDataFlexRow}>product:
          <span className={style.coderReferenceDataClientData}> TickTock</span>
        </h4>
        <h4 className={style.coderReferenceDataFlexRow}>story:</h4>

        <p className={style.coderReferenceDataFlexRow}>
          TickTock is client management and invoice tracker platform designed to
          support freelance programmers and designers working on multiple projects
          in parallel. One of the key functionalities is the automated invoice
          and money transfer feature: through API calls and statement processing
          the platform is connected with the largest US banks to make the
          customer-client relationship easier.
        </p>
        <div className={style.stackAndResource}>
          <div>
            <h4 className={style.coderReferenceDataFlexRow}>Stack:</h4>
{/*             <IconsContainer
              iconList={[
                EIcons.AWS,
                EIcons.RAILS,
                EIcons.MONGO,
                EIcons.REACT,
              ]} */}
            />
          </div>
          <div>
            <h4 className={style.view}>View project:</h4>
            <div className={style.viewSvgContainer}>
              <SVG className={style.viewSvgContainerIcon} src={iconMultiMedia}/>
            </div>
          </div>
        </div>

      </div>
      <div className={style.pager}>
        {/* <PagerBtn direction={EDirection.Left}/> */}
        <div/>
        <PagerBtn direction={EDirection.Right}/>
      </div>
    </div>
  )
}

export default CoderReference
