import * as React from "react"
import SVG from "react-inlinesvg"

import iconArrowLeft from "@static/svg/arrow-left.svg"
import iconArrowRight from "@static/svg/arrow-right.svg"

import style from "./PagerBtn.scss"

export enum EDirection {
  Left = "Prev",
  Right = "Next",
}

interface IPagerBtnProps {
  direction: EDirection
}

const PagerBtn: React.FunctionComponent<IPagerBtnProps> = (props) => {
  const { direction } = props

  return (direction === EDirection.Left) ? (
    <div className={style.pagerBtn}>
      <SVG src={iconArrowLeft}/>
      <div className={style.pagerBtnText}>{EDirection.Left}</div>
    </div>
  ) : (
    <div className={style.pagerBtn}>
      <div className={style.pagerBtnText}>{EDirection.Right}</div>
      <SVG src={iconArrowRight}/>
    </div>
  )
}

export default PagerBtn
